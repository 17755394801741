import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import { PageFooter } from "@components/page-footer";
import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
var timer;
export default {
  data: function data() {
    return {
      loginPage: true,
      isShow: false,
      verifyCode: null,
      activeIndex: 1,
      time: 90,
      showTime: false,
      showErr: false,
      userInfo: {}
    };
  },
  //离开之前清除缓存
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    localStorage.removeItem('stopTime');
    localStorage.removeItem("activeInfo");
    next();
  },
  mounted: function mounted() {
    var activeInfo = localStorage.getItem("activeInfo");
    if (activeInfo) {
      var info = JSON.parse(activeInfo);
      this.activeIndex = 2;
      this.userInfo = info;
    }
    if (localStorage.getItem('stopTime')) {
      if (0 < this.getCount() && this.getCount() < 90) {
        this.showTime = true;
        this.getMsg();
      }
    }
  },
  computed: {},
  methods: {
    //返回首页
    toIndex: function toIndex() {
      this.$router.push({
        path: "/"
      });
    },
    //登录
    toLogin: function toLogin() {
      this.$router.push({
        path: "/account/login"
      });
    },
    inputChange: function inputChange(e) {
      this.isShow = false;
      // console.log(e.target,this.userInfo.account)
    },
    inputBlur: function inputBlur(e) {
      var _this = this;
      var url = "/pjyy-deepexi-user-center/api/v1/common/forgetPwdFirst?account=".concat(this.userInfo.account);
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code == '200') {
          _this.userInfo = res.data.data;
          _this.isShow = false;
        } else {
          _this.isShow = true;
        }
      });
      // this.isShow = true
      // console.log(e,this.userInfo.account)
    },
    //下一步
    nextTap: function nextTap() {
      var _this2 = this;
      var url = "/pjyy-deepexi-user-center/api/v1/common/forgetPwdFirst?account=".concat(this.userInfo.account);
      this.$getHttpClient().get(url).then(function (res) {
        if (res.data.code == '200') {
          _this2.activeIndex = 2;
          localStorage.setItem("activeInfo", JSON.stringify(res.data.data));
        }
      });
    },
    getVerifyCode: function getVerifyCode() {
      var _this3 = this;
      var url = "/pjyy-deepexi-user-center/api/v1/common/forgetPwdTwo?account=".concat(this.userInfo.account);
      this.$getHttpClient().post(url).then(function (res) {
        if (res.data.code == '200') {
          localStorage.setItem('stopTime', new Date().getTime() + 90 * 1000);
          _this3.getMsg();
        }
      });
    },
    //获取验证码
    getMsg: function getMsg() {
      var _this4 = this;
      if (timer) {
        clearInterval(timer);
      }
      var that = this;
      this.time = this.getCount();
      //设置button效果，开始计时
      timer = setInterval(function () {
        if (that.time <= 0) {
          clearInterval(timer); //停止计时器
          that.showTime = false;
        } else {
          _this4.showTime = true;
          that.time--;
        }
      }, 1000);
    },
    restPsd: function restPsd() {
      var _this5 = this;
      if (!this.verifyCode) {
        this.$buefy.toast.open({
          message: "请输入验证码！",
          type: "is-danger"
        });
        return;
      }
      var url = '/pjyy-deepexi-user-center/api/v1/common/forgetPwdThree';
      var params = {
        id: this.userInfo.id,
        loginSource: 'PC',
        verifyCode: this.verifyCode
      };
      this.$getHttpClient().post(url, params).then(function (res) {
        if (res.data.code == '200') {
          _this5.$buefy.toast.open({
            message: "重置密码成功",
            type: "is-success"
          });
          _this5.$router.push({
            path: '/account/login'
          });
        }
      });
    },
    //通过本地缓存获取倒计时
    getCount: function getCount() {
      var now = new Date().getTime();
      if (localStorage.getItem('stopTime')) {
        var stopTime = localStorage.getItem('stopTime');
        var remainTime = parseInt((stopTime - now) / 1000);
        //设定的倒计时，
        if (remainTime > 0) {
          //剩下多少秒
          return remainTime;
        } else {
          localStorage.setItem('stopTime', 0);
          return 0;
        }
      } else {
        localStorage.setItem('stopTime', new Date().getTime() + 90 * 1000);
        return 90;
      }
    }
  },
  components: {
    PageFooter: PageFooter
  }
};